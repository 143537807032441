import { type Effect, type Params as IEffectParams } from "./effect";
// import { convertObjToU8Arr, EffectParamsScheme } from './scheme';

const getInstance = (() => {
  // let instance: Effect | null = null;

  return async () => {
    return new Promise<Effect>((resolve) => {
      const win = window as unknown as { effect: () => Effect };

      const waitEffect = async (): Promise<Effect> => {
        if (typeof win.effect === "function") {
          const instance = win.effect();
          console.log("effect instance created");
          return instance;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 100));
          return await waitEffect();
        }
      };

      // if (!instance) {
      resolve(waitEffect());
      // }

      // resolve(instance);
    });
  };
})();

export async function init(): Promise<Effect> {
  console.log("init");
  const instance = await getInstance();
  console.log("instance", instance);
  instance._init();
  console.info(`Web BUILD TIME: ${process.env.GATSBY_BUILD_TIME}`);
  return instance;
}
let first = false;
export async function start(params: IEffectParams, instance: Effect) {
  const { writeArrayToMemory, stringToUTF8, _malloc } = instance;
  // const u8arr = convertObjToU8Arr(params, EffectParamsScheme)
  // const size = u8arr.byteLensgth * u8arr.BYTES_PER_ELEMENT
  // const ptr = _malloc(size)
  const paramJSON = JSON.stringify(params);
  const ptr = _malloc(paramJSON.length + 1);

  const source = "App";
  const sourcePtr = _malloc(source.length + 1);
  stringToUTF8(source, sourcePtr, source.length + 1);
  stringToUTF8(paramJSON, ptr, paramJSON.length + 1);

  // writeArrayToMemory(u8arr, ptr)

  instance._start(sourcePtr, ptr);
}

export async function run(instance: Effect): Promise<string> {
  // console.log(instance);
  const { HEAPU8, _getMaxNum } = instance;
  const ptr = instance._run();
  const memory = new Uint8Array(HEAPU8.buffer);
  const strArr: string[] = [];

  const max = _getMaxNum();

  // 遍历 color_t 结构体
  for (let i = 0; i < max; i++) {
    const seq = [
      memory[ptr + i * 4],
      memory[ptr + i * 4 + 1],
      memory[ptr + i * 4 + 2],
      memory[ptr + i * 4 + 3],
    ];
    strArr.push(...seq.map((c) => `00${c.toString(16)}`.slice(-2)));
  }

  return strArr.join("");
}
