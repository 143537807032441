import {
  ArcRotateCamera,
  Color3,
  Color4,
  Effect,
  Engine,
  Mesh,
  MeshBuilder,
  Scene,
  ShaderMaterial,
  StandardMaterial,
  Texture,
  Vector3,
} from "@babylonjs/core";
import type { HeadFC, PageProps } from "gatsby";
import LRUCache from "lru-cache";
import React, { useEffect, useRef } from "react";

import {
  init as initEffect,
  run as runEffect,
  start as startEffect,
} from "../templates/Home/EffectLoader";
import { type Color } from "../templates/Home/effect";

import * as classes from "./Home.module.scss";

interface UpdateLightEffectParams {
  effect_id: number;
  effect_desc: number;
  // hex string array
  fore_color?: string[];
  fore_color_index?: number[];
  fore_color_brightness: number;
  // hex string
  back_color?: string;
  back_color_brightness?: 100;
  // 速度枚举 1-10，出厂默认 5
  speed: 5;
  light: number;
}

interface WindowWithLightEffect {
  __eufyUpdateLightEffectParams: (params: UpdateLightEffectParams[]) => void;
  __eufyStopLightEffect: () => void;
  flutter_inappwebview?: {
    callHandler<T extends unknown>(
      name: string,
      ...args: unknown[]
    ): Promise<T>;
  };
}

function supressLog<T extends unknown>(fn: () => T): T {
  const log = console.log;
  console.log = () => {};
  const result = fn();
  console.log = log;
  return result;
}

function callHandler(name: string, ...args: unknown[]) {
  const win = window as unknown as WindowWithLightEffect;

  console.log(`call handler with name: ${name}`);
  if (typeof win.flutter_inappwebview === "object") {
    return win.flutter_inappwebview.callHandler(name, ...args);
  }
}

function onLightEffectReady() {
  callHandler("onLightEffectReady");
}

function onLightEffectError(code: number) {
  callHandler("onLightEffectError", { code });
}

function initClors(planesArr) {
  for (let i = 0; i < planesArr.length; i++) {
    for (let j = 0; j < planesArr[i].length; j++) {
      const plane = planesArr[i][j];
      const shaderMtl = plane.material as ShaderMaterial;
      const intColors = new Color3(0, 0, 0);
      shaderMtl.setColor3("lightColor", intColors);
    }
  }
}
let tIDs = {};

let playing = true;

// 屋檐灯
const TEXTURE_CHANNELS = [
  `01_Corona Light687.png`,

  `01_Corona Light781.png`,
  `01_Corona Light782.png`,
  `01_Corona Light783.png`,
  `01_Corona Light784.png`,
  `01_Corona Light785.png`,
  `01_Corona Light786.png`,
  `01_Corona Light787.png`,
  `01_Corona Light788.png`,
  `01_Corona Light789.png`,
  `01_Corona Light790.png`,

  `01_Corona Light791.png`,
  `01_Corona Light792.png`,
  `01_Corona Light793.png`,
  `01_Corona Light794.png`,
  `01_Corona Light795.png`,
  `01_Corona Light796.png`,
  `01_Corona Light797.png`,
  `01_Corona Light798.png`,
  `01_Corona Light799.png`,
  `01_Corona Light800.png`,

  `01_Corona Light801.png`,
  `01_Corona Light802.png`,
  `01_Corona Light803.png`,
  `01_Corona Light804.png`,
  `01_Corona Light805.png`,
  `01_Corona Light806.png`,
  `01_Corona Light807.png`,
  `01_Corona Light808.png`,
  `01_Corona Light809.png`,
  `01_Corona Light810.png`,

  `01_Corona Light811.png`,
  `01_Corona Light812.png`,
  `01_Corona Light813.png`,
  `01_Corona Light814.png`,
  `01_Corona Light815.png`,
  `01_Corona Light816.png`,
].map((v) => `/house/render/eaves/${v}`);
// 右边路径灯
const Role_TEXTURE_CHANNELS = [`01.png`, `03.png`, `05.png`, `07.png`].map(
  (v) => `/house/render/role/${v}`
);
// 左边路径灯
const Role_TEXTURE_CHANNELS_LEFT = [`02.png`, `04.png`, `06.png`, `08.png`].map(
  (v) => `/house/render/role/${v}`
);
// 左边射灯
const SPOT_TEXTURE_CHANNELS = [`03.png`, `02.png`, `01.png`, `04.png`].map(
  (v) => `/house/render/spot/${v}`
);
// 右边射灯
const SPOT_TEXTURE_CHANNELS_RIGHT = [
  `05.png`,
  `06.png`,
  `07.png`,
  `08.png`,
].map((v) => `/house/render/spot/${v}`);

// 灯泡串
const BULB_TEXTURE_CHANNELS = [
  `01_Corona Light821.png`,
  `01_Corona Light823.png`,
  `01_Corona Light825.png`,
  `01_Corona Light827.png`,
  `01_Corona Light829.png`,

  `01_Corona Light835.png`,
  `01_Corona Light836.png`,
  `01_Corona Light837.png`,
  `01_Corona Light838.png`,
  `01_Corona Light839.png`,

  `01_Corona Light845.png`,
  `01_Corona Light846.png`,
  `01_Corona Light847.png`,
  `01_Corona Light848.png`,
  `01_Corona Light849.png`,
].map((v) => `/house/render/bulb/${v}`);

const IndexPage: React.FC<PageProps> = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const engineRef = useRef<Engine>();
  const sceneRef = useRef<Scene>();
  const planesRef = useRef<Array<Mesh>>();
  const rolePlanesRef = useRef<Array<Mesh>>();
  const rolePlanesRefLeft = useRef<Array<Mesh>>();
  const spotPlanesRef = useRef<Array<Mesh>>();
  const spotPlanesRefRight = useRef<Array<Mesh>>();
  const bulbRightPlanesRef = useRef<Array<Mesh>>();

  useEffect(() => {
    let lastTime = 0;
    const delta = 1000 / 30;
    if (canvasRef.current) {
      const canvas = canvasRef.current;

      const engine = supressLog(() => new Engine(canvas, true));
      engineRef.current = engine;

      const scene = new Scene(engine);
      sceneRef.current = scene;

      scene.setRenderingAutoClearDepthStencil(0, false, false, false);

      scene.clearColor = Color4.FromHexString("#0b182aFF");

      const channels = TEXTURE_CHANNELS.map((v) => new Texture(v, scene));
      const RolChannels = Role_TEXTURE_CHANNELS.map(
        (v) => new Texture(v, scene)
      );
      const RolChannelsLeft = Role_TEXTURE_CHANNELS_LEFT.map(
        (v) => new Texture(v, scene)
      );
      const SpotChannels = SPOT_TEXTURE_CHANNELS.map(
        (v) => new Texture(v, scene)
      );
      const SpotChannelsRight = SPOT_TEXTURE_CHANNELS_RIGHT.map(
        (v) => new Texture(v, scene)
      );
      const BulbRightChannels = BULB_TEXTURE_CHANNELS.map(
        (v) => new Texture(v, scene)
      );
      const main = new Texture("/house/main-scene-dark-v6-min.png", scene);

      const shaderMaterial = new ShaderMaterial(
        "light",
        scene,
        {
          vertex: "light",
          fragment: "light",
        },
        {
          attributes: ["position", "normal", "uv"],
          uniforms: ["worldViewProjection", "lightColor", "distance", "bg"],
        }
      );

      Effect.ShadersStore["lightVertexShader"] = `
      attribute vec3 position;
      attribute vec3 normal;
      attribute vec2 uv;
      uniform mat4 worldViewProjection;
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec2 vUV;

      void main(void) {
        gl_Position = worldViewProjection * vec4(position, 1.0);
        vPosition = position;
        vNormal = normal;
        vUV = uv;
      }
      `;

      Effect.ShadersStore["lightFragmentShader"] = `
      precision highp float;

      uniform sampler2D channel;
      uniform vec3 lightColor;
      uniform float distance;
      uniform bool is_spot;
      uniform sampler2D bg;
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec2 vUV;

      void main(void) {
        vec4 texColor = texture2D(channel, vUV);
        vec4 bgColor = texture2D(bg, vUV);

        // r value as alpha
        float alpha = texColor.r;
        // float bgAlpha = 1.0 - distance;

        // Calculate the brightness of the color
        float brightness = dot(lightColor, vec3(0.299, 0.587, 0.114));
      
        // If the brightness is less than the threshold, set alpha to 0
        if (brightness < 0.05) {
          alpha = 0.0;
        }

        //  贴图的r 通道作为 alpha 通道
        //  1.0 - alpha 作为背景的 alpha 通道
        // 最终颜色 = 灯光颜色 * 贴图 alpha + 背景颜色 * 背景 alpha
        // 
        gl_FragColor = vec4(lightColor, alpha);
        if(is_spot == true){
          vec3 finalColor = lightColor * alpha + bgColor.rgb * (1.0 - alpha);
          gl_FragColor = vec4(finalColor, alpha);
        }
        // ignore dark
        // if (distance <= 0.05) {
        //   // alpha = 0.0;
        // } else {
        // }
      }
      `;

      shaderMaterial.alpha = 0;
      shaderMaterial.backFaceCulling = false;

      const planeSize = { width: 1400, height: 1268, sideOrientation: 1 };

      const planes = channels.map((channel, i) => {
        const plane = MeshBuilder.CreatePlane(`plane${i}`, planeSize, scene);
        plane.setEnabled(false);
        const shaderMtl = shaderMaterial.clone(`shaderMaterial${i}`);
        shaderMtl.setTexture("channel", channel);
        shaderMtl.setTexture("bg", main);
        shaderMtl.forceDepthWrite = true;
        plane.material = shaderMtl;

        return plane;
      });
      planesRef.current = planes;

      // 路径
      const rolePlanes = RolChannels.map((channel, i) => {
        const plane = MeshBuilder.CreatePlane(
          `rolePlanes${i}`,
          planeSize,
          scene
        );
        plane.setEnabled(false);
        const shaderMtl = shaderMaterial.clone(`shaderMaterial${i}`);
        shaderMtl.setTexture("channel", channel);
        shaderMtl.setTexture("bg", main);
        shaderMtl.forceDepthWrite = true;
        plane.material = shaderMtl;

        return plane;
      });
      rolePlanesRef.current = rolePlanes;

      const rolePlanesLeft = RolChannelsLeft.map((channel, i) => {
        const plane = MeshBuilder.CreatePlane(
          `rolePlanes${i}`,
          planeSize,
          scene
        );
        plane.setEnabled(false);
        const shaderMtl = shaderMaterial.clone(`shaderMaterial${i}`);
        shaderMtl.setTexture("channel", channel);
        shaderMtl.setTexture("bg", main);
        shaderMtl.forceDepthWrite = true;
        plane.material = shaderMtl;

        return plane;
      });
      rolePlanesRefLeft.current = rolePlanesLeft;

      // 射灯
      const spotPlanes = SpotChannels.map((channel, i) => {
        const plane = MeshBuilder.CreatePlane(
          `spotPlanes${i}`,
          planeSize,
          scene
        );
        plane.setEnabled(false);
        const shaderMtl = shaderMaterial.clone(`shaderMaterial${i}`);
        shaderMtl.setTexture("channel", channel);
        shaderMtl.setTexture("bg", main);
        shaderMtl.forceDepthWrite = true;
        plane.material = shaderMtl;

        return plane;
      });
      spotPlanesRef.current = spotPlanes;

      // 右边射灯
      const spotPlanesRight = SpotChannelsRight.map((channel, i) => {
        const plane = MeshBuilder.CreatePlane(
          `spotPlanes${i}`,
          planeSize,
          scene
        );
        plane.setEnabled(false);
        const shaderMtl = shaderMaterial.clone(`shaderMaterial${i}`);
        shaderMtl.setTexture("channel", channel);
        shaderMtl.setTexture("bg", main);
        shaderMtl.forceDepthWrite = true;
        plane.material = shaderMtl;

        return plane;
      });
      spotPlanesRefRight.current = spotPlanesRight;

      // 右边灯泡串
      const bulbRightPlanes = BulbRightChannels.map((channel, i) => {
        const plane = MeshBuilder.CreatePlane(
          `bulbRightPlanes${i}`,
          planeSize,
          scene
        );
        plane.setEnabled(false);
        const shaderMtl = shaderMaterial.clone(`shaderMaterial${i}`);
        shaderMtl.setTexture("channel", channel);
        shaderMtl.setTexture("bg", main);
        shaderMtl.forceDepthWrite = true;
        plane.material = shaderMtl;

        return plane;
      });
      bulbRightPlanesRef.current = bulbRightPlanes;

      const mainPlane = MeshBuilder.CreatePlane("mainPlane", planeSize, scene);

      const mainPlaneMaterial = new StandardMaterial(
        "mainPlaneMaterial",
        scene
      );
      mainPlaneMaterial.backFaceCulling = false;
      mainPlaneMaterial.diffuseTexture = main;
      mainPlaneMaterial.specularPower = 100;
      // 提亮主体
      mainPlaneMaterial.emissiveColor = new Color3(1, 1, 1);

      mainPlane.material = mainPlaneMaterial;

      // 默认相机的位置刚好将模型居中，且左右间距为对象宽度一半
      scene.createDefaultCameraOrLight(true, true, false);

      if (scene.activeCamera) {
        const camera = scene.activeCamera as ArcRotateCamera;
        camera.setPosition(
          new Vector3(
            camera.position.x / 2,
            camera.position.y / 2,
            camera.position.z / 2
          )
        );
      }

      engine.runRenderLoop(() => {
        const now = Date.now();
        // 控制帧率
        if (now - lastTime >= delta) {
          lastTime = now;
          if (scene.activeCamera) {
            scene.render();
          }
        }
      });

      window.addEventListener("resize", () => {
        engine.resize();
      });

      const sall = <T extends unknown>(fn: () => T) => fn();

      sall(async () => {
        let caches = new LRUCache<string, Color3>({
          max: 100,
        });
        let cachesDistance: Record<string, number> = {};
        let isUnsupportedTriggered = false;
        const win = window as unknown as WindowWithLightEffect;

        win.__eufyStopLightEffect = () => {
          playing = false;
        };

        const __eufyUpdateLightEffectParamsStart = async (params) => {
          // caches.clear();
          // cachesDistance = {};
          playing = true;
          isUnsupportedTriggered = false;
          const instanceArr = [];
          // 两组路径灯
          const role = params.find((v) => v.light === 2);
          if (role) {
            params.push({ ...role, light: 21 });
          }
          // 两组射灯
          const spot = params.find((v) => v.light === 3);
          if (spot) {
            params.push({ ...spot, light: 31 });
          }

          params.forEach(async (p, index) => {
            console.log(`params: ${index}`);
            const instance = await initEffect();
            console.log(`params2: ${index}`);
            instanceArr.push(instance);
            caches.clear();
            cachesDistance = {};
            const backColorR = parseInt(p.back_color?.slice(0, 2) || "00", 16);
            const backColorG = parseInt(p.back_color?.slice(2, 4) || "00", 16);
            const backColorB = parseInt(p.back_color?.slice(4, 6) || "00", 16);
            const backColorW = parseInt(p.back_color?.slice(4, 6) || "00", 16);

            const tabs: Color[] = [];

            if (p.fore_color?.length) {
              const fore_color = p.fore_color || [];
              fore_color.forEach((hex, i) => {
                // const hex = fore_color[v]
                // console.log({hex});
                if (hex) {
                  const color = {
                    r: parseInt(hex.slice(0, 2) || "00", 16),
                    g: parseInt(hex.slice(2, 4) || "00", 16),
                    b: parseInt(hex.slice(4, 6) || "00", 16),
                    w: parseInt(hex.slice(6, 8) || "00", 16),
                  };
                  tabs.push(color);
                }
              });
            }

            const startParams = {
              crc16: Math.round(Math.random() * 255),
              head: Math.round(Math.random() * 255),
              effect_id: p.effect_id,
              effect_desc: p.effect_desc,
              effect_speed: p.speed,
              fore_color: {
                fc_num: p.fore_color_index?.length || 0,
                fc_tab: tabs,
                fc_lumi: p.fore_color_brightness ?? 100,
              },
              back_color: {
                bc: {
                  r: backColorR,
                  g: backColorG,
                  b: backColorB,
                  w: backColorW,
                },
                bc_lumi: p.back_color_brightness ?? 100,
              },
              cloud_id: 0,
              reserve: 0,
              diy_group: 0,
              light_show: 0,
            };

            console.log(JSON.stringify(startParams, null, 2));

            startEffect(startParams, instance);
            // 缓存颜色对象，不频繁创建颜色
            const colors = new Array<Color3>(60);
            const parms = {
              colors,
              instance,
              caches,
              cachesDistance,
              light: p.light,
            };
            if (p.light === 1) {
              loop({ planes: planes, ...parms });
            } else if (p.light === 2) {
              loop({ planes: rolePlanes, ...parms });
            } else if (p.light === 3) {
              loop({ planes: spotPlanes, ...parms });
            } else if (p.light === 4) {
              loop({ planes: bulbRightPlanes, ...parms });
            } else if (p.light === 21) {
              loop({ planes: rolePlanesLeft, ...parms });
            } else if (p.light === 31) {
              loop({ planes: spotPlanesRight, ...parms });
            }
            // ...
          });

          async function loop(params) {
            const { planes, colors, instance, caches, cachesDistance, light } =
              params;
            clearTimeout(tIDs[`tID_${light}`]);
            const hexStr = await runEffect(instance);
            // hex Arr 固定为长度 60
            const hexArr = hexStr.match(/[a-zA-Z0-9]{8}/gi) || [];
            for (let i = 0; i < planes.length; i++) {
              const plane = planes[i];
              plane.setEnabled(true);
              const idx = i % hexArr.length;
              const hex = hexArr[idx];
              if (hex) {
                const shaderMtl = plane.material as ShaderMaterial;

                const color =
                  caches.get(hex) ||
                  (() => {
                    let c: Color3;
                    if (!colors[idx]) {
                      colors[idx] = new Color3(0, 0, 0);
                    }
                    c = colors[idx];
                    // 白色灯权重
                    const weight = 0.4;
                    const r = parseInt(hex.slice(0, 2) || "00", 16);
                    const g = parseInt(hex.slice(2, 4) || "00", 16);
                    const b = parseInt(hex.slice(4, 6) || "00", 16);
                    const w = parseInt(hex.slice(6, 8) || "00", 16);

                    return c.set(
                      Math.min((r + w * weight) / 255, 1),
                      Math.min((g + w * weight) / 255, 1),
                      Math.min((b + w * weight) / 255, 1)
                    );
                  })();
                // 将 HSV 的 V 值作为距离，仅显示 0.4-1.0 之间
                const distance =
                  cachesDistance[hex] ?? Math.max(color.r, color.g, color.b);

                if (!caches.has(hex)) {
                  caches.set(hex, color.clone());
                }
                if (!cachesDistance[hex]) {
                  cachesDistance[hex] = distance;
                }
                shaderMtl.setColor3("lightColor", color);
                shaderMtl.setFloat("distance", distance);
                const is_spot = light === 3 || light === 31 ? 1 : 0;
                // const is_spot = 0;
                shaderMtl.setFloat("is_spot", is_spot);
              } else {
                // 避免重复调用
                if (!isUnsupportedTriggered) {
                  // 灯效不支持时，返回空值，需要通知 App
                  onLightEffectError(1);
                  isUnsupportedTriggered = true;
                }
                return;
              }
            }
            if (!playing) {
              initClors([planes, rolePlanes, spotPlanes, bulbRightPlanes]);
              // discard
            } else {
              window.requestAnimationFrame(() => {
                // 频率尽量保持在每 20ms 一次
                const idle = 20;
                tIDs[`tID_${light}`] = window.setTimeout(() => {
                  loop(params);
                }, idle);
              });
            }
          }
          // loop(planes)
        };
        win.__eufyUpdateLightEffectParams = (params) => {
          // playing = false;
          win.__eufyStopLightEffect();
          setTimeout(() => {
            __eufyUpdateLightEffectParamsStart(params);
          }, 50);
        };
        onLightEffectReady();
      });

      return () => {
        for (var key in tIDs) {
          clearTimeout(tIDs[key]);
        }
        scene.dispose();
        sceneRef.current = undefined;
        engine.dispose();
        engineRef.current = undefined;
      };
    }
  }, []);

  return (
    <main className={classes.root}>
      <div className={classes.box}>
        <canvas className={classes.canvas} ref={canvasRef} />
      </div>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Light Effects Preview</title>
    <script src="/effect.js" type="text/javascript" />
  </>
);
